import { Button } from "devextreme-react";
import { Form, GroupItem, Label, SimpleItem } from "devextreme-react/form";
import "devextreme-react/text-area";
import React, { Component } from "react";
import { deployShop } from "../../../../actions/shopActions";
import { getWebShopByWebShopsId } from "../../../../actions/webShopActions";
import store from "../../../../store";
import "./WebShops.scss";

class Webshop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            webShop: undefined,
            webShopId: this.props.match.params.id,
            deployStatus: 0,
        };

        this.onStoreNameChanged = this.onStoreNameChanged.bind(this);
        this.onLicenseChanged = this.onLicenseChanged.bind(this);
        this.deployButton = this.deployButton.bind(this);
        this.getDeployStatus = this.getDeployStatus.bind(this);
        this.deployShop = this.deployShop.bind(this);

        this.validationRules = {
            name: [{ type: "required", message: "Name muss angegeben werden!" }],
            webShopType: [{ type: "required", message: "Der WebShop-Typ muss angegeben werden!" }],
            validityDate: [{ type: "required", message: "Das Ablaufdatum muss angegeben werden!" }],
            username: [{ type: "required", message: "Der Benutzername muss angegeben werden!" }],
            password: [{ type: "required", message: "Das Passwort muss angegeben werden!" }],
        };
    }

    componentDidMount() {
        store.dispatch(getWebShopByWebShopsId(this.state.webShopId)).then((json) => {
            this.setState({ webShop: json });
        });
    }

    onStoreNameChanged(e) {}

    onLicenseChanged(e) {}

    deployButton() {
        if (this.state.deployStatus === 0) {
            return (
                <div style={{ display: "grid", marginTop: 20 }}>
                    <Button text={"Deploy"} type="default" onClick={this.deployShop} />
                </div>
            );
        } else {
            return (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>Deploy-Status: {this.state.deployStatusString}</div>
                    <Button text={"Status aktualisieren"} type="default" onClick={this.getDeployStatus} />
                </div>
            );
        }
    }

    getDeployStatus() {}

    deployShop() {
        store.dispatch(deployShop(this.state.licenseId, this.state.webShopId));
    }

    render() {
        return (
            <div className="webshops">
                <div>
                    <Form formData={this.state.webShop} disabled={this.state.loading}>
                        <GroupItem cssClass="first-group" colCount={4}>
                            <GroupItem colSpan={3}>
                                <SimpleItem
                                    dataField="StoreName"
                                    editorOptions={{ onValueChanged: this.onStoreNameChanged }}
                                    validationRules={this.validationRules.name}
                                >
                                    <Label text={"Name"} />
                                </SimpleItem>
                                <SimpleItem dataField="Url" editorOptions={{ onValueChanged: this.onLicenseChanged }}>
                                    <Label text={"Url"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="WebShopType"
                                    disabled
                                    validationRules={this.validationRules.webShopType}
                                >
                                    <Label text={"Webshop-Typ"} />
                                </SimpleItem>
                                <SimpleItem dataField="WebshopVersion" disabled>
                                    <Label text={"Webshop-Version"} />
                                </SimpleItem>
                                <SimpleItem dataField="WebshopVersionsnummer" disabled>
                                    <Label text={"Webshop-Versionsnummer"} />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="Revoked"
                                    editorType="dxCheckBox"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                >
                                    <Label text={"Lizenz gesperrt"} />
                                </SimpleItem>
                                {this.state.webShop?.WebShopType === 70 && <SimpleItem render={this.deployButton} />}
                            </GroupItem>
                        </GroupItem>
                        {this.state.webShop?.WebShopType === 70 && (
                            <GroupItem cssClass="second-group" colCount={2}>
                                <GroupItem colSpan={1}>
                                    <SimpleItem
                                        dataField="title"
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                        <Label text={"Titel"} />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="Username"
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                        validationRules={this.validationRules.username}
                                    >
                                        <Label text={"Benutzername"} />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="Password"
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                        validationRules={this.validationRules.password}
                                    >
                                        <Label text={"Passwort"} />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colSpan={1}>
                                    <SimpleItem
                                        dataField="Validitydate"
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                        validationRules={this.validationRules.validityDate}
                                    >
                                        <Label text={"Gültig bis"} />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="ServerUrl"
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                        <Label text={"Server-Url"} />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="WebSocketUrl"
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                        <Label text={"Websocket-Url"} />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="AppClientID"
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                        <Label text={"AppClient-ID"} />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="customDomain"
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                        <Label text={"Custom Domain"} />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="azureB2CPoolId"
                                        editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                        <Label text={"Azure B2C User Pool ID"} />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                        )}
                    </Form>
                </div>
            </div>
        );
    }
}

export default Webshop;
