import { Button, Form, LoadPanel, Popup, Tabs, TextBox } from "devextreme-react";
import { Item } from "devextreme-react/form";
import { Button as TextBoxButton } from "devextreme-react/text-box";
import { CompareRule, RequiredRule, Validator } from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserData, updateUserPassword, updateUserRole } from "../../../../actions/userActions";
import { getToast } from "../../../../helpers/requestHelpers";
import eyeSlashIcon from "../../../../img/eye-slash-solid.svg";
import eyeIcon from "../../../../img/eye-solid.svg";
import store from "../../../../store";
import "./PopupEditUser.scss";
const tabs = [
    {
        id: 0,
        text: "Login Daten",
        content: "loginData",
    },
    {
        id: 1,
        text: "Persönliche Daten",
        content: "userData",
    },
];

class PopupEditUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            currentTab: tabs[0],
            currentContent: tabs[0].content,
            userData: undefined,
            password: "",
            newPassword: "",
            newPasswordConfirm: "",
        };

        this.handleTabsOptionChanged = this.handleTabsOptionChanged.bind(this);
        this.updateUserData = this.updateUserData.bind(this);
        this.updateLoginData = this.updateLoginData.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.passwordComparison = this.passwordComparison.bind(this);
        this.onOldPasswordChanged = this.onOldPasswordChanged.bind(this);
        this.onNewPasswordChanged = this.onNewPasswordChanged.bind(this);
        this.onNewPasswordConfirmChanged = this.onNewPasswordConfirmChanged.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.userData) {
            if (state.userData === undefined) {
                state.userData = props.userData;
            } else {
                if (state.userData.Email !== props.userData.Email) {
                    state.userData = { ...props.userData, newPassword: "", newPasswordConfirm: "" };
                }
            }
        }
        return state;
    }

    passwordComparison() {
        return this.state.newPassword;
    }

    onOldPasswordChanged(e) {
        this.setState({
            oldPassword: e.value,
        });
    }

    onNewPasswordChanged(e) {
        this.setState({
            newPassword: e.value,
        });
    }

    onNewPasswordConfirmChanged(e) {
        this.setState({
            newPasswordConfirm: e.value,
        });
    }

    render() {
        if (this.props.userData) {
            var firstName = this.props.userData.FirstName ? this.props.userData.FirstName : "";
            var lastName = this.props.userData.LastName ? this.props.userData.LastName : "";
            var fullName = "";
            firstName === "" && lastName === "" ? (fullName = "kein Name") : (fullName = firstName + " " + lastName);
        }
        return (
            <Popup
                visible={this.props.showEditPopup}
                closeOnOutsideClick={true}
                onHiding={this.props.hide}
                title={fullName}
            >
                <div>
                    {this.state.userData && (
                        <div>
                            <Tabs
                                dataSource={tabs}
                                defaultSelectedItem={this.state.currentTab}
                                onOptionChanged={this.handleTabsOptionChanged}
                            />
                            <div className="popupEditUserContent" style={{ marginTop: "15px" }}>
                                <LoadPanel visible={this.state.isLoading} />
                                {this.state.currentContent === "userData" && (
                                    <div>
                                        <Form formData={this.state.userData} onFieldDataChanged={this.updateUserData}>
                                            <Item
                                                label={{ text: "Vorname" }}
                                                dataField={"FirstName"}
                                                editorType={"dxTextBox"}
                                            />
                                            <Item
                                                label={{ text: "Nachname" }}
                                                dataField={"LastName"}
                                                editorType={"dxTextBox"}
                                            />
                                            <Item
                                                label={{ text: "E-Mail" }}
                                                dataField={"Email"}
                                                editorType={"dxTextBox"}
                                            />
                                            <Item
                                                label={{ text: "Telefonnummer" }}
                                                dataField={"PhoneNumber"}
                                                editorType={"dxTextBox"}
                                            />
                                        </Form>
                                    </div>
                                )}
                                {this.state.currentContent === "loginData" && (
                                    <div className="loginDataForm">
                                        <form>
                                            <div className="dx-fieldset">
                                                <div className="dx-fieldset-header">{"Login Daten"}</div>
                                                {this.props.userData.Id === this.props.identity.user.Id && (
                                                    <div className="dx-field">
                                                        <div className="dx-field-label">{"Altes Passwort"}</div>
                                                        <div className="dx-field-value">
                                                            <TextBox
                                                                mode={this.state.showOldPassword ? "text" : "password"}
                                                                value={this.state.oldPassword}
                                                                onValueChanged={this.onOldPasswordChanged}
                                                            >
                                                                <TextBoxButton
                                                                    name="showPassword"
                                                                    style={{ marginRight: 5 }}
                                                                    options={{
                                                                        location: "after",
                                                                        type: "default",
                                                                        color: "#fff",
                                                                        icon: this.state.showOldPassword
                                                                            ? eyeSlashIcon
                                                                            : eyeIcon,
                                                                        onClick: () => {
                                                                            this.setState({
                                                                                showOldPassword:
                                                                                    !this.state.showOldPassword,
                                                                            });
                                                                        },
                                                                    }}
                                                                ></TextBoxButton>
                                                                <Validator>
                                                                    <RequiredRule
                                                                        message={"Dieses Feld wird benötigt"}
                                                                    />
                                                                </Validator>
                                                            </TextBox>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="dx-field">
                                                    <div className="dx-field-label">{"Neues Passwort"}</div>
                                                    <div className="dx-field-value">
                                                        <TextBox
                                                            mode={this.state.showNewPassword ? "text" : "password"}
                                                            value={this.state.newPassword}
                                                            onValueChanged={this.onNewPasswordChanged}
                                                        >
                                                            <TextBoxButton
                                                                name="showPassword"
                                                                style={{ marginRight: 5 }}
                                                                options={{
                                                                    location: "after",
                                                                    type: "default",
                                                                    color: "#fff",
                                                                    icon: this.state.showNewPassword
                                                                        ? eyeSlashIcon
                                                                        : eyeIcon,
                                                                    onClick: () => {
                                                                        this.setState({
                                                                            showNewPassword:
                                                                                !this.state.showNewPassword,
                                                                        });
                                                                    },
                                                                }}
                                                            ></TextBoxButton>
                                                            <Validator>
                                                                <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                            </Validator>
                                                        </TextBox>
                                                    </div>
                                                </div>
                                                <div className="dx-field">
                                                    <div className="dx-field-label"> {"Neues Passwort bestätigen"}</div>
                                                    <div className="dx-field-value">
                                                        <TextBox
                                                            mode={
                                                                this.state.showNewPasswordConfirm ? "text" : "password"
                                                            }
                                                            value={this.state.newPasswordConfirm}
                                                            onValueChanged={this.onNewPasswordConfirmChanged}
                                                        >
                                                            <TextBoxButton
                                                                name="showPassword"
                                                                style={{ marginRight: 5 }}
                                                                options={{
                                                                    location: "after",
                                                                    type: "default",
                                                                    color: "#fff",
                                                                    icon: this.state.showNewPasswordConfirm
                                                                        ? eyeSlashIcon
                                                                        : eyeIcon,
                                                                    onClick: () => {
                                                                        this.setState({
                                                                            showNewPasswordConfirm:
                                                                                !this.state.showNewPasswordConfirm,
                                                                        });
                                                                    },
                                                                }}
                                                            ></TextBoxButton>
                                                            <Validator>
                                                                <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                                <CompareRule
                                                                    message={"Passwörter stimmen nicht überein"}
                                                                    comparisonTarget={this.passwordComparison}
                                                                />
                                                            </Validator>
                                                        </TextBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <p className="passwordHint">
                                            {
                                                "Wichtig: Passwörter müssen mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten!"
                                            }
                                        </p>
                                        <div className="changePasswordButtonDiv">
                                            <Button
                                                className="changePasswordButton"
                                                text="Passwort ändern"
                                                type="default"
                                                disabled={
                                                    this.state.newPassword !== this.state.newPasswordConfirm ||
                                                    (this.state.oldPassword === "" &&
                                                        this.props.userData.Id === this.props.identity.user.Id) ||
                                                    this.state.newPasswordConfirm === "" ||
                                                    this.state.newPassword === ""
                                                }
                                                onClick={this.changePassword}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Popup>
        );
    }

    handleTabsOptionChanged(e) {
        if (e.name === "selectedItem") {
            this.setState({
                currentTab: e.value.id,
                currentContent: e.value.content,
            });
        }
    }

    updateUserData(e) {
        this.setState({ isLoading: true });

        store.dispatch(updateUserData({ ...this.state.userData, [e.dataField]: e.value })).then((result) => {
            notify(getToast("Änderungen erfolgreich", "success"));
            this.props.reloadUsers();
            this.setState({ isLoading: false, ...this.state.userData, [e.dataField]: e.value });
        });
    }

    changePassword() {
        var oldPassword = this.state.oldPassword;
        var newPasswordConfirm = this.state.newPasswordConfirm;

        if (this.state.isLoading === false) {
            this.setState({ isLoading: true });
            store
                .dispatch(
                    updateUserPassword({
                        newPassword: newPasswordConfirm,
                        Id: this.state.userData.Id,
                        oldPassword: oldPassword,
                    })
                )
                .then((result) => {
                    if (result.status === 200) {
                        notify(getToast("Passwort erfolgreich geändert", "success"));
                        this.setState({ isLoading: false, newPassword: "", oldPassword: "", newPasswordConfirm: "" });
                    } else {
                        notify(getToast(result.errorMessage, "error"));
                        this.setState({ isLoading: false });
                    }
                });
        }
    }

    updateLoginData(e) {
        if (e.dataField === "UserRoles") {
            this.setState({ isLoading: true });

            store.dispatch(updateUserRole(this.state.userData.Id, e.value)).then((result) => {
                notify(getToast("Passwort erfolgreich geändert", "success"));
                this.setState({ isLoading: false, ...this.state.userData, [e.dataField]: e.value });
            });
        }

        if (e.dataField === "oldPassword" || e.dataField === "newPassword" || e.dataField === "newPasswordConfirm") {
            var oldPassword = this.state.userData.oldPassword;
            var newPassword = this.state.userData.newPassword;
            var newPasswordConfirm = this.state.userData.newPasswordConfirm;

            if (newPassword !== undefined && newPasswordConfirm !== undefined) {
                if (newPassword === newPasswordConfirm) {
                    if (this.state.isLoading === false) {
                        this.setState({ isLoading: true });
                        store
                            .dispatch(
                                updateUserPassword({
                                    newPassword: newPasswordConfirm,
                                    Id: this.state.userData.Id,
                                    oldPassword: oldPassword,
                                })
                            )
                            .then((result) => {
                                notify(getToast("Passwort erfolgreich geändert", "success"));
                                this.setState({
                                    isLoading: false,
                                    userData: {
                                        ...this.state.userData,
                                        newPassword: undefined,
                                        oldPassword: undefined,
                                        newPasswordConfirm: undefined,
                                    },
                                });
                            });
                    }
                } else {
                    notify(getToast("Passwörter stimmen nicht überein", "error"));
                }
            }
        }
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(PopupEditUser);
