import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux"; // Add this import if not already present
import { withRouter } from "react-router-dom";
import {
    addShopToLicense,
    changeImageVersion,
    deleteInstance,
    getAvailableVersions,
    getLicenseById,
    reactivateLicense,
    revokeLicense,
    startInstance,
    stopInstance,
    updateLicense,
} from "../../../actions/licensesActions";
import { getAllModules } from "../../../actions/moduleActions";
import { deployShop, updateShop } from "../../../actions/shopActions";
import store from "../../../store";

function License(props) {
    const [licenseId] = useState(props.licenseId);
    const [license, setLicense] = useState(undefined);
    const [deployStatus, setDeployStatus] = useState(0);
    const [deployStatusString, setDeployStatusString] = useState("");
    const [modules, setModules] = useState([]);
    const [availableVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState(undefined);
    const [showRevokeLicensePopup, setShowRevokeLicensePopup] = useState(false);
    const [showDeleteInstancePopup, setShowDeleteInstancePopup] = useState(false);
    const [showChangeVersionPopup, setShowChangeVersionPopup] = useState(false);
    const [revokedReason, setRevokedReason] = useState("");
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info" });
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getAvailableVersions()); // Dispatch the action instead of calling the function directly
        dispatch(getAllModules()).then((response) => setModules(response));
        if (licenseId) {
            dispatch(getLicenseById(licenseId, window.location.origin)).then((result) => {
                setLicense(result);
                setDeployStatus(result.Container ? result.deployStatus : 0);
                setDeployStatusString(result.Container ? result.deployStatusString : "");
                setSelectedVersion(result.containerImage);
            });
        }
    }, [licenseId, dispatch]);

    const removeByAttr = (arr, attr, value) => {
        var i = arr.length;
        while (i--) {
            if (arr[i] && arr[i].hasOwnProperty(attr) && arguments.length > 2 && arr[i][attr] === value) {
                arr.splice(i, 1);
            }
        }
        return arr;
    };

    const deploy = () => {
        setDeployStatusString("(1/4) Deploy gestartet");
        setDeployStatus(1);
        store.dispatch(deploy(licenseId, selectedVersion ? selectedVersion : "latest"));
    };

    const handleDeploy = (data) => {
        store.dispatch(deployShop(licenseId, data.shopId));
    };

    const handleInsert = (e) => {
        store.dispatch(addShopToLicense(licenseId, e.data.shopName, e.data.shopKind, e.data.url, e.data.image));
    };

    const handleUpdate = (e) => {
        store.dispatch(updateShop(e.data));
    };

    const onVersionChanged = (e) => {
        setSelectedVersion(e.value);
    };

    const deployButtons = () => {
        if (deployStatus === 0) {
            return (
                <div style={{ display: "grid", gap: 10 }}>
                    <FormControl fullWidth>
                        <InputLabel>Version</InputLabel>
                        <Select
                            value={
                                selectedVersion || license.containerImage || "foodgenius.azurecr.io/bios/bios:release"
                            }
                            onChange={(e) => onVersionChanged({ value: e.target.value })}
                        >
                            {availableVersions.map((version) => (
                                <MenuItem key={version} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={deploy} disabled={license && license.deployFrontendUrl === ""}>
                        Deploy
                    </Button>
                    <Button
                        variant="contained"
                        onClick={toggleChangeVersionPopup}
                        disabled={selectedVersion === license.containerImage}
                    >
                        Version ändern
                    </Button>
                    {license && license.clusterStatus !== "deployed" && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                store
                                    .dispatch(
                                        startInstance(
                                            "bios",
                                            license ? license.deployFrontendUrl.replaceAll(".", "-") : ""
                                        )
                                    )
                                    .then(() => {
                                        store
                                            .dispatch(getLicenseById(licenseId, window.location.origin))
                                            .then((result) => {
                                                setLicense(result);
                                                setDeployStatus(result.Container ? result.deployStatus : 0);
                                                setDeployStatusString(
                                                    result.Container ? result.deployStatusString : ""
                                                );
                                                setSelectedVersion(result.containerImage);
                                            });
                                    });
                            }}
                        >
                            Instanz starten
                        </Button>
                    )}
                    {license && license.clusterStatus === "deployed" && (
                        <Button
                            variant="contained"
                            onClick={() =>
                                store
                                    .dispatch(
                                        stopInstance(
                                            "bios",
                                            license ? license.deployFrontendUrl.replaceAll(".", "-") : ""
                                        )
                                    )
                                    .then(() => {
                                        store
                                            .dispatch(getLicenseById(licenseId, window.location.origin))
                                            .then((result) => {
                                                setLicense(result);
                                                setDeployStatus(result.Container ? result.deployStatus : 0);
                                                setDeployStatusString(
                                                    result.Container ? result.deployStatusString : ""
                                                );
                                                setSelectedVersion(result.containerImage);
                                            });
                                    })
                            }
                        >
                            Instanz stoppen
                        </Button>
                    )}
                    {license && license.Revoked === false && (
                        <Button variant="contained" color="error" onClick={() => toggleRevokeLicensePopup()}>
                            Lizenz sperren
                        </Button>
                    )}
                    {license && license.Revoked && (
                        <Button variant="contained" color="error" onClick={() => toggleRevokeLicensePopup()}>
                            Lizenz entsperren
                        </Button>
                    )}
                    <Button variant="contained" color="error" onClick={() => toggleDeleteInstancePopup()}>
                        Instanz löschen
                    </Button>
                </div>
            );
        } else {
            return (
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography>Deploy-Status: {deployStatusString}</Typography>
                        <Button variant="contained" onClick={getDeployStatus}>
                            Status aktualisieren
                        </Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <FormControl fullWidth>
                            <InputLabel>Version</InputLabel>
                            <Select
                                value={selectedVersion}
                                onChange={(e) => onVersionChanged({ value: e.target.value })}
                            >
                                {availableVersions.map((version) => (
                                    <MenuItem key={version} value={version}>
                                        {version}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button variant="contained" onClick={toggleChangeVersionPopup}>
                            Version ändern
                        </Button>
                    </div>
                </div>
            );
        }
    };

    const toggleRevokeLicensePopup = () => {
        setShowRevokeLicensePopup(!showRevokeLicensePopup);
    };

    const toggleDeleteInstancePopup = () => {
        setShowDeleteInstancePopup(!showDeleteInstancePopup);
    };

    const toggleChangeVersionPopup = () => {
        setShowChangeVersionPopup(!showChangeVersionPopup);
    };

    const onSelectionChanged = (e) => {
        let modules = e.value.map((v) => {
            return { Module: v };
        });
        setLicense({
            ...license,
            ModulesBought: modules,
        });
    };

    const getDeployStatus = () => {
        store.dispatch(getDeployStatus(licenseId)).then((result) => {
            setDeployStatusString(result.deployStatusString);
            setDeployStatus(result.deployStatus);
        });
    };

    const onLicenseChanged = (e) => {
        if (license) {
            store.dispatch(updateLicense(license)).then((result) => {
                // Handle success or error
            });
        }
    };

    const onStoreNameChanged = (e) => {
        if (license) {
            store.dispatch(updateLicense(license)).then((result) => {
                // Handle success or error
            });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return;
        setSnackbar({ ...snackbar, open: false });
    };

    const showSnackbar = (message, severity = "info") => {
        setSnackbar({ open: true, message, severity });
    };

    return (
        <div>
            {license && (
                <Stack spacing={3}>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={3}>
                        <Paper elevation={3} sx={{ p: 3, flex: 1 }}>
                            <Typography variant="h6" gutterBottom>
                                Allgemein
                            </Typography>
                            <Stack direction="row" spacing={3}>
                                <Stack spacing={4} sx={{ width: "75%" }}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        value={license.StoreName}
                                        onChange={(e) => onStoreNameChanged({ value: e.target.value })}
                                        required
                                    />
                                    <TextField
                                        fullWidth
                                        label="Inhaber / GF"
                                        value={license.Owner}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                        required
                                    />
                                    <TextField fullWidth label="Lizenznummer" value={license.LicenseNumber} disabled />
                                    <TextField
                                        fullWidth
                                        label="Unternehmensschlüssel"
                                        value={license.companyKey}
                                        disabled
                                    />
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label="Lizenzablauf"
                                            value={moment(license.ValidityDate)}
                                            onChange={(newValue) => {
                                                setLicense({
                                                    ...license,
                                                    ValidityDate: newValue.format("YYYY-MM-DD"),
                                                });
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        fullWidth
                                        label="Cluster Status"
                                        value={license.clusterStatus}
                                        InputProps={{ readOnly: true }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Cluster Version"
                                        value={license.clusterVersion}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Stack>
                                <Stack spacing={3} sx={{ width: "25%" }}>
                                    {deployButtons()}
                                </Stack>
                            </Stack>
                        </Paper>
                        <Paper elevation={3} sx={{ p: 3, flex: 1 }}>
                            <Typography variant="h6" gutterBottom>
                                Inhaber
                            </Typography>
                            <Stack direction="row" spacing={3}>
                                <Stack spacing={3} sx={{ width: "50%" }}>
                                    <TextField
                                        fullWidth
                                        label="Titel"
                                        value={license.title}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Nachname Inhaber"
                                        value={license.ownerLastName}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Hausnummer"
                                        value={license.houseNumber}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Postleitzahl"
                                        value={license.Zip}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                </Stack>
                                <Stack spacing={3} sx={{ width: "50%" }}>
                                    <TextField
                                        fullWidth
                                        label="Inhaber Vorname"
                                        value={license.ownerFirstName}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Straße"
                                        value={license.Street}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Adresszusatz"
                                        value={license.AddressDetails}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Stadt"
                                        value={license.City}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                </Stack>
                            </Stack>
                            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                                Rechtliche Texte
                            </Typography>
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    label="Datenschutz Vereinbarung"
                                    value={license.dataProtectionPolicy}
                                    onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                />
                                <TextField
                                    fullWidth
                                    label="Impressum"
                                    value={license.impressum}
                                    onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                />
                            </Stack>
                        </Paper>
                    </Stack>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={3}>
                        <Paper elevation={3} sx={{ p: 3, flex: 1 }}>
                            <Typography variant="h6" gutterBottom>
                                Datenbank
                            </Typography>
                            <Stack direction="row" spacing={3}>
                                <Stack spacing={3} sx={{ width: "50%" }}>
                                    <TextField
                                        fullWidth
                                        label="Datenbank Server"
                                        value={license.databaseServer}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Datenbank Benutzer"
                                        value={license.databaseUser}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Container Image"
                                        value={license.containerImage}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                </Stack>
                                <Stack spacing={3} sx={{ width: "50%" }}>
                                    <TextField
                                        fullWidth
                                        label="Datenbank Name"
                                        value={license.databaseName}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Datenbank Passwort"
                                        value={license.databasePassword}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                </Stack>
                            </Stack>
                        </Paper>{" "}
                        <Paper elevation={3} sx={{ p: 3, flex: 1 }}>
                            <Typography variant="h6" gutterBottom>
                                URLs
                            </Typography>
                            <Stack direction="row" spacing={3}>
                                <Stack spacing={3} sx={{ width: "50%" }}>
                                    <TextField
                                        fullWidth
                                        label="Deploy-Frontend-Url"
                                        value={license.deployFrontendUrl}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Shop-Frontend-Url"
                                        value={license.shopFrontendUrl}
                                        onChange={(e) => onLicenseChanged({ value: e.target.value })}
                                    />
                                </Stack>
                                <Stack spacing={3} sx={{ width: "50%" }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={license.ratingEnabled}
                                                onChange={(e) => onLicenseChanged({ value: e.target.checked })}
                                            />
                                        }
                                        label="Bewertungen aktiviert"
                                    />
                                </Stack>
                            </Stack>
                        </Paper>
                    </Stack>
                </Stack>
            )}
            <Dialog open={showRevokeLicensePopup} onClose={toggleRevokeLicensePopup}>
                <DialogTitle>Lizenz sperren</DialogTitle>
                <DialogContent>
                    <TextField
                        multiline
                        rows={4}
                        fullWidth
                        placeholder="Grund für die Sperrung"
                        value={license ? license.RevokedReason : ""}
                        onChange={(e) => setRevokedReason(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleRevokeLicensePopup}>Abbrechen</Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            if (license && !license.Revoked) {
                                store.dispatch(revokeLicense(licenseId, revokedReason));
                            } else {
                                store.dispatch(reactivateLicense(licenseId));
                            }
                        }}
                    >
                        {license && !license.Revoked ? "Lizenz sperren" : "Lizenz entsperren"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showDeleteInstancePopup} onClose={toggleDeleteInstancePopup}>
                <DialogTitle>Instanz löschen</DialogTitle>
                <DialogContent>
                    <Typography>Sind Sie sicher, dass Sie die Instanz löschen möchten?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleDeleteInstancePopup}>Abbrechen</Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            store
                                .dispatch(deleteInstance(licenseId))
                                .then(() => {
                                    showSnackbar("Instanz erfolgreich gelöscht", "success");
                                    props.history.push("/lizenzen");
                                })
                                .catch((error) => {
                                    showSnackbar("Fehler beim Löschen der Instanz: " + error.message, "error");
                                });
                            toggleDeleteInstancePopup();
                        }}
                    >
                        Instanz löschen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showChangeVersionPopup} onClose={toggleChangeVersionPopup}>
                <DialogTitle>Version ändern</DialogTitle>
                <DialogContent>
                    <Typography>Achtung: Das Ändern der Version führt zu einem Neustart der Instanz.</Typography>
                    <Typography>Möchten Sie fortfahren?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleChangeVersionPopup}>Abbrechen</Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            store.dispatch(changeImageVersion(licenseId, selectedVersion));
                            toggleChangeVersionPopup();
                        }}
                    >
                        Version ändern
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: "100%" }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default withRouter(License);
