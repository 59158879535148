import AssignmentIcon from "@mui/icons-material/Assignment";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import StoreIcon from "@mui/icons-material/Store";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { DataGrid, ResponsiveBox } from "devextreme-react";
import { Col, Item, Location, Row } from "devextreme-react/responsive-box";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAuditByResellerId } from "../../../actions/auditActions";
import { getAllLicensesByResellerId } from "../../../actions/licensesActions";
import { getAllSubReseller } from "../../../actions/resellerActions";
import { getUsageLogs } from "../../../actions/usageLogActions";
import store from "../../../store";
import "./Dashboard.scss";

class Dashboard extends Component {
    render() {
        return (
            <div id="dashboard">
                {this.props.identity.user && (
                    <ResponsiveBox>
                        <Row ratio={1} />
                        <Row ratio={1} />
                        <Col ratio={1} />
                        <Col ratio={1} />
                        <Item>
                            <Location row={0} col={0} />
                            <div className="dashboard-item">
                                <header>
                                    <VpnKeyIcon /> Lizenzen
                                </header>
                                <div className="data">
                                    <DataGrid
                                        dataSource={
                                            new DataSource({
                                                store: new CustomStore({
                                                    loadMode: "raw",
                                                    load: () => {
                                                        return store.dispatch(
                                                            getAllLicensesByResellerId(
                                                                this.props.identity.user.resellerId
                                                            )
                                                        );
                                                    },
                                                }),
                                            })
                                        }
                                        columns={[
                                            {
                                                dataField: "LicenseNumber",
                                            },
                                            {
                                                dataField: "StoreName",
                                            },
                                            {
                                                dataField: "Owner",
                                            },
                                        ]}
                                        showBorders={true}
                                        showRowLines={true}
                                    />
                                </div>
                                <footer>
                                    <Link to="/lizenzen">zu den Lizenzen</Link>
                                </footer>
                            </div>
                        </Item>
                        <Item>
                            <Location row={0} col={1} />
                            <div className="dashboard-item">
                                <header>
                                    <StoreIcon /> Reseller
                                </header>
                                <div className="data">
                                    <DataGrid
                                        dataSource={
                                            new DataSource({
                                                store: new CustomStore({
                                                    loadMode: "raw",
                                                    load: () => {
                                                        return store.dispatch(
                                                            getAllSubReseller(this.props.identity.user.resellerId)
                                                        );
                                                    },
                                                }),
                                            })
                                        }
                                        columns={[
                                            {
                                                dataField: "resellerName",
                                            },
                                            {
                                                dataField: "licenseName",
                                            },
                                        ]}
                                        showBorders={true}
                                        showRowLines={true}
                                    />
                                </div>
                                <footer>
                                    <Link to="/resellers">zu den Resellern</Link>
                                </footer>
                            </div>
                        </Item>
                        <Item>
                            <Location row={1} col={0} />
                            <div className="dashboard-item">
                                <header>
                                    <LockOpenIcon /> UsageLogs
                                </header>
                                <div className="data">
                                    <DataGrid
                                        dataSource={
                                            new DataSource({
                                                store: new CustomStore({
                                                    load: (loadOptions) => {
                                                        return store.dispatch(
                                                            getUsageLogs(
                                                                loadOptions,
                                                                this.props.identity.user.resellerId
                                                            )
                                                        );
                                                    },
                                                }),
                                            })
                                        }
                                        columns={[
                                            {
                                                dataField: "Name",
                                            },
                                            {
                                                dataField: "ClientName",
                                            },
                                            {
                                                dataField: "timeStamp",
                                                dataType: "datetime",
                                            },
                                        ]}
                                        remoteOperations={{ paging: true, sorting: false }}
                                        paging={{
                                            enabled: true,
                                            pageSize: 10,
                                        }}
                                        pager={{ visible: false }}
                                        showBorders={true}
                                        showRowLines={true}
                                    />
                                </div>
                                <footer>
                                    <Link to="/usageLogs">zu den UsageLogs</Link>
                                </footer>
                            </div>
                        </Item>
                        <Item>
                            <Location row={1} col={1} />
                            <div className="dashboard-item">
                                <header>
                                    <AssignmentIcon /> Audit
                                </header>
                                <div className="data">
                                    <DataGrid
                                        dataSource={
                                            new DataSource({
                                                store: new CustomStore({
                                                    load: (loadOptions) => {
                                                        return store.dispatch(
                                                            getAuditByResellerId(
                                                                loadOptions,
                                                                this.props.identity.user.resellerId
                                                            )
                                                        );
                                                    },
                                                }),
                                            })
                                        }
                                        columns={[
                                            {
                                                dataField: "name",
                                            },
                                            {
                                                dataField: "timeStamp",
                                                dataType: "datetime",
                                            },
                                            {
                                                dataField: "tableName",
                                            },
                                        ]}
                                        remoteOperations={{ paging: true }}
                                        paging={{
                                            enabled: true,
                                            pageSize: 10,
                                        }}
                                        pager={{ visible: false }}
                                        showBorders={true}
                                        showRowLines={true}
                                    />
                                </div>
                                <footer>
                                    <Link to="/audit">zum Audit</Link>
                                </footer>
                            </div>
                        </Item>
                    </ResponsiveBox>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Dashboard);
