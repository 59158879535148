import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DnsIcon from "@mui/icons-material/Dns";
import HomeIcon from "@mui/icons-material/Home";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MenuIcon from "@mui/icons-material/Menu";
import StoreIcon from "@mui/icons-material/Store";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { List } from "devextreme-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Navigation.scss";

class Navigation extends Component {
    constructor() {
        super();

        this.state = {
            navigationOpen: false,
        };

        this.navigationItems = [
            {
                id: 1,
                text: "Navigation",
                icon: <MenuIcon fontSize={"large"} />,
                link: () => {
                    this.setState({
                        navigationOpen: !this.state.navigationOpen,
                    });
                },
            },
            {
                id: 2,
                text: "Zurück",
                icon: <ArrowBackIcon fontSize={"large"} />,
                link: () => {
                    this.props.history.goBack();
                },
            },
            {
                id: 3,
                text: "Start",
                icon: <HomeIcon fontSize={"large"} />,
                link: () => {
                    this.goToPage("/");
                },
            },
            {
                id: 4,
                text: "Lizenzen",
                icon: <VpnKeyIcon fontSize={"large"} />,
                link: () => {
                    this.goToPage("/lizenzen");
                },
            },
            {
                id: 5,
                text: "Reseller",
                icon: <StoreIcon fontSize={"large"} />,
                link: () => {
                    this.goToPage("/resellers");
                },
            },
            {
                id: 6,
                text: "Audit",
                icon: <AssignmentIcon fontSize={"large"} />,
                link: () => {
                    this.goToPage("/audit");
                },
            },
            {
                id: 7,
                text: "UsageLogs",
                icon: <LockOpenIcon fontSize={"large"} />,
                link: () => {
                    this.goToPage("/usageLogs");
                },
            },
            {
                id: 8,
                text: "Server-Urls",
                icon: <DnsIcon fontSize={"large"} />,
                link: () => {
                    this.goToPage("/serverUrls");
                },
            },
            {
                id: 9,
                text: "Orchestration",
                icon: <AccountTreeIcon fontSize={"large"} />,
                link: () => {
                    this.goToPage("/orchestration");
                },
            },
            {
                id: 10,
                text: "Module",
                icon: <AccountTreeIcon fontSize={"large"} />,
                link: () => {
                    this.goToPage("/modules");
                },
            },
        ];

        this.goToPage = this.goToPage.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
    }

    goToPage(path) {
        this.props.history.push(path);
    }

    handleListItemClick(e) {
        e.itemData.link();
    }

    render() {
        return (
            <React.Fragment>
                {this.props.identity.user && (
                    <div id="navigation" className={this.state.navigationOpen ? "open" : "closed"}>
                        <List
                            items={this.navigationItems}
                            onItemClick={this.handleListItemClick}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            itemRender={(e) => {
                                return (
                                    <div>
                                        <span style={{ display: "flex", alignItems: "center" }} title={e.text}>
                                            {e.icon !== "" && e.icon} <span style={{ marginLeft: 15 }}>{e.text}</span>
                                        </span>
                                    </div>
                                );
                            }}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(withRouter(Navigation));
