import { Tabs } from "devextreme-react";
import React, { Component } from "react";

import License from "./License";
import Modules from "./modules/Modules";
import Webshops from "./webShops/WebShops";

import ApiKey from "./apiKey/ApiKey";
import Sync from "./sync/Sync";

class LicenseTabsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseId: this.props.match.params.id,
            selectedIndex: 0,
        };

        this.onValueChanged = this.onValueChanged.bind(this);
        this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);

        this.tabs = [
            { text: "Lizenz", content: <License licenseId={this.state.licenseId} /> },
            { text: "Online-Shops", content: <Webshops licenseId={this.state.licenseId} /> },
            { text: "Module", content: <Modules licenseId={this.state.licenseId} /> },
            { text: "Api Key", content: <ApiKey licenseId={this.state.licenseId} /> },
            { text: "Sync", content: <Sync licenseId={this.state.licenseId} /> },
        ];
    }

    onTabsSelectionChanged(args) {
        if (args.name === "selectedIndex") {
            this.setState({
                selectedIndex: args.value,
            });
        }
    }

    onValueChanged(args) {
        this.setState({
            selectedIndex: args.value,
        });
    }

    render() {
        const { selectedIndex } = this.state;
        return (
            <div>
                <Tabs
                    dataSource={this.tabs}
                    selectedIndex={selectedIndex}
                    onOptionChanged={this.onTabsSelectionChanged}
                />
                <div className="tabsContent">{this.tabs[selectedIndex].content}</div>
            </div>
        );
    }
}

export default LicenseTabsManagement;
