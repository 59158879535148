import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button, DateBox } from "devextreme-react";
import React from "react";

class EditModulePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            license: this.props.license,
            selectedModule: this.props.selectedLicenseModule,
            selectedEndDate: undefined,
            selectedStartDate: undefined,
            today: 0,
            defaultEndDate: 0,
        };

        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onDeleteModule = this.onDeleteModule.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
    }

    componentDidMount() {
        let thisDate = new Date();
        this.setState({
            selectedEndDate: thisDate,
        });

        if (this.props.selectedLicenseModule !== undefined) {
            this.setState({
                selectedModule: this.props.selectedLicenseModule,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedLicenseModule?.moduleId !== this.state.selectedModule?.moduleId) {
            this.setState({
                selectedModule: this.props.selectedLicenseModule,
                selectedStartDate: this.props.selectedLicenseModule.startDate,
                selectedEndDate: this.props.selectedLicenseModule.expiryDate,
            });
        }
    }

    onDeleteModule(e) {
        this.props.onDeleteModule(this.state.selectedEndDate);
    }

    onStartDateChanged(e) {
        this.setState({
            selectedStartDate: new Date(e.value),
        });
    }

    onEndDateChanged(e) {
        this.setState({
            selectedEndDate: new Date(e.value),
        });
    }

    saveChanges() {
        this.props.saveChanges({
            moduleId: this.props.selectedLicenseModule.moduleId,
            startDate: this.state.selectedStartDate,
            expiryDate: this.state.selectedEndDate,
        });
    }

    render() {
        return (
            <div>
                {this.state.selectedModule && (
                    <Dialog
                        aria-labelledby="customized-dialog-title"
                        fullWidth={true}
                        maxWidth={"false"}
                        open={this.props.showEditModulePopUp}
                    >
                        <DialogTitle id="customized-dialog-title" onClose={this.props.toggleEditModulePopUp}>
                            Modul Bearbeiten
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <h3>Startdatum</h3>
                                <DateBox
                                    value={this.state.selectedStartDate}
                                    type="date"
                                    onValueChanged={(e) => {
                                        this.onStartDateChanged(e);
                                    }}
                                />

                                <h3>Enddatum</h3>
                                <DateBox
                                    value={this.state.selectedEndDate}
                                    type="date"
                                    onValueChanged={(e) => {
                                        this.onEndDateChanged(e);
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button height={34} onClick={this.props.toggleEditModulePopUp} color="primary">
                                Abbrechen
                            </Button>
                            <Button height={34} onClick={this.saveChanges} color="primary">
                                Speichern
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </div>
        );
    }
}

export default EditModulePopUp;
